import React, { useState } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {
  withStyles,
  makeStyles,
} from '@material-ui/core/styles';
import './header.styles.scss';
import copy from 'copy-to-clipboard';
import { Tooltip } from '@material-ui/core';

import Unicorn from 'src/assets/img/unicorn.svg';
import Harmony from 'src/assets/img/HarmonyLogo.svg';
import { handle3DotsIntoBetween } from '../../utils/handleSliceString.js';
import { selectAddressWallet } from '../../redux/user/user.selectors';

const useStylesBootstrap = makeStyles((theme) => ({
  arrow: {
    color: '#252728',
  },
  tooltip: {
    backgroundColor: '#252728',
  },
}));
// spare code
function BootstrapTooltip(props) {
  const classes = useStylesBootstrap();

  return <Tooltip arrow classes={classes} {...props} />;
}

const Header = (props) => {
  const {
    openPopup,
    isOpenWalletPopup,
    addressWallet,
  } = props;

  const [openTooltip, setOpenTooltip] = useState(false);

  return (
    <div className="header-container">
      <div
        className="header-left"
        onClick={() => {
          window.open(
            'https://timeless.space/app',
            '_blank',
          );
        }}
      >
        <p>
          via Timeless <img src={Unicorn} />
        </p>
      </div>
      <div
        className="header-right"
        onClick={() => {
          setOpenTooltip(true);
          copy(addressWallet);
          setTimeout(() => {
            setOpenTooltip(false);
          }, 2000);
        }}
      >
        {addressWallet && (
          <BootstrapTooltip
            onClose={() => setOpenTooltip(false)}
            open={openTooltip}
            disableFocusListener
            disableHoverListener
            disableTouchListener
            title="Copied!"
            background="red"
            arrow={true}
            placement="top"
          >
            <div className="checkin-button">
              <img
                src={Harmony}
                styles={{
                  position: 'relative',
                  top: '-3px',
                }}
                width="22px"
              />
              &nbsp; {handle3DotsIntoBetween(addressWallet)}
            </div>
          </BootstrapTooltip>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  addressWallet: selectAddressWallet,
});

const mapDispatchToProps = () => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Header);
