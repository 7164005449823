import React, { useState, useEffect } from 'react';
import * as LottiePlayer from '@lottiefiles/lottie-player';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { useParams } from 'react-router-dom';
import ListCard from 'src/components/card/list-card/list-card.component';
import QrCode from 'src/assets/img/sampleQR.svg';
import './list-card-page.styles.scss';
import { formatDateGallery } from '../../utils/timeFormat';
import { CSSTransition } from 'react-transition-group';

import Header from '../../components/header/header.component';
import FooterGallery from '../../components/footer/footer.component';

import { selectAddressWallet } from '../../redux/user/user.selectors';
import { selectAddressEventList } from '../../redux/event/event.selector';
import { fetchEventFromAddress } from '../../redux/event/event.action';

const ListCardPage = (props) => {
  const {
    envRoute,
    addressWallet,
    addressEventList,
    fetchEventFromAddress,
  } = props;
  const [isMouted, setIsMouted] = useState(false);
  const [heightFooter, setHeightFooter] = useState();
  const params = useParams();

  useEffect(() => {
    setIsMouted(true);
  }, []);

  useEffect(() => {
    if (params.address && addressEventList.length === 0) {
      fetchEventFromAddress({
        address: params.address,
        history: props.history,
        env: envRoute,
      });
    }
  }, []);

  return (
    <div className="list-card-page">
      <Header addressWallet={addressWallet} />
      <CSSTransition
        in={isMouted}
        timeout={2000}
        classNames="fade"
        unmountOnExit
      >
        <div className="container-gallery">
          {addressEventList?.length != 0 ? (
            <>
              <div className="title">
                My minted memories
                <span className="time">
                  {formatDateGallery()}
                </span>
              </div>
              <ListCard envRoute={envRoute} />
            </>
          ) : (
            <div className="empty-gallery">
              <div className="empty-left">
                <lottie-player
                  src={
                    'https://assets5.lottiefiles.com/temp/lf20_8wtnLF.json'
                  }
                  background="transparent"
                  speed="1"
                  style={{ height: '300px' }}
                  loop
                  autoplay
                />
              </div>
              <div className="empty-right">
                <div className="title">
                  Looks like an empty collection
                </div>
                <div className="qr-part">
                  <div className="qr-content">
                    <img src={QrCode} />
                  </div>
                  <div className="text">
                    <div className="title">
                      Join Fellow Harmonauts
                    </div>
                    <div className="content">
                      iOS user? Scan to download Timeless -
                      join this event, subscribe to future
                      Harmony events, and collect NFT drops.
                    </div>
                  </div>
                </div>
                <div className="sss"></div>
              </div>
            </div>
          )}

          <FooterGallery />
        </div>
      </CSSTransition>
      <div className="footer" />
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  addressWallet: selectAddressWallet,
  addressEventList: selectAddressEventList,
});

const mapDispatchToProps = (dispatch) => ({
  fetchEventFromAddress: (data) =>
    dispatch(fetchEventFromAddress(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ListCardPage);
