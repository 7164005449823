import Axios from 'axios';
import Cookies from '../utils/cookies';
import Toast from '../utils/toast';

const instance = Axios.create({
  baseURL: process.env.REACT_APP_API,
});

instance.interceptors.request.use(
  function (config) {
    const token = Cookies.get('token');
    const configWithToken = {
      ...config,
      headers: { ...config.headers, authorization: token },
    };
    return configWithToken;
  },
  function (error) {
    console.log('Resquest error:', error);
    return Promise.reject(error);
  },
);
instance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (!error.response) {
      Toast.fire({
        icon: 'error',
        title: 'Network Error!',
      });
      error.response = {
        data: {
          success: false,
          data: 'Network Error!',
        },
      };
    }
    if (error.response) {
      if (error.response.status === 400) {
        Toast.fire({
          icon: 'error',
          title: error.response.data.error.message,
        });
      }
    }
    return Promise.reject(error);
  },
);
export default instance;
