import {
  takeLatest,
  call,
  put,
  all,
  select,
} from 'redux-saga/effects';

import {
  addExample,
  getExamples,
  updateExample,
  deleteExample,
  uploadImage,
} from '../../api/example-api';

import ExampleActionTypes from './example.types';

import Toast from '../../utils/toast';

import {
  fetchListSuccess,
  fetchListFailure,
  addItemSuccess,
  addItemFailure,
  editItemSuccess,
  editItemFailure,
  putParamsFetch,
  deleteItemSuccess,
  deleteItemFailure,
} from './example.actions';

import { selectParamsFetch } from './example.selectors';

export function* fetchListWithParams({
  payload: { pagination },
}) {
  try {
    yield put(putParamsFetch({ pagination }));
    yield fetchList();
  } catch (error) {
    yield put(fetchListFailure(error.response.data));
  }
}

export function* fetchListWithSearch({ payload }) {
  try {
    const params = { pagination: { page: 1, limit: 10 } };
    if (payload && payload !== '') {
      params.search = payload;
    }
    yield put(putParamsFetch(params));
    yield fetchList();
  } catch (error) {
    yield put(fetchListFailure(error.response.data));
  }
}

export function* fetchList() {
  try {
    const params = yield select(selectParamsFetch);
    const list = yield getExamples({
      ...params.pagination,
      search: params.search,
    });
    yield put(fetchListSuccess(list));
  } catch (error) {
    yield put(fetchListFailure(error.response.data));
  }
}

export function* onFetchListStart() {
  yield takeLatest(
    ExampleActionTypes.FETCH_LIST_START_EXAMPLE,
    fetchListWithParams,
  );
}

export function* addItem({
  payload: { name, info, avatar },
}) {
  try {
    const data = { name, info };
    if (avatar) {
      const resUpload = yield uploadImage(avatar);
      data.avatar = resUpload.data.fileName;
    }
    yield addExample(data);
    yield fetchList();
    yield put(addItemSuccess());
    Toast.fire({
      icon: 'success',
      title: 'Added successfully!',
    });
  } catch (error) {
    yield put(addItemFailure(error.response.data));
  }
}

export function* updateItem({
  payload: { _id, name, info, avatar, fileUpload },
}) {
  try {
    const data = { _id, name, info };
    if (!avatar) {
      const resUpload = yield uploadImage(fileUpload);
      data.avatar = resUpload.data.fileName;
    } else {
      data.avatar = avatar;
    }
    yield updateExample(data);
    yield put(editItemSuccess(data));
    Toast.fire({
      icon: 'success',
      title: 'Updated successfully!',
    });
  } catch (error) {
    yield put(editItemFailure(error.response.data));
  }
}

export function* deleteItem({ payload }) {
  try {
    yield deleteExample(payload);
    yield fetchList();
    yield put(deleteItemSuccess());
    Toast.fire({
      icon: 'success',
      title: 'Deleted successfully!',
    });
  } catch (error) {
    yield put(deleteItemFailure(error.response.data));
  }
}

export function* onUpdateItemStart() {
  yield takeLatest(
    ExampleActionTypes.EDIT_ITEM_START_EXAMPLE,
    updateItem,
  );
}

export function* onAddStart() {
  yield takeLatest(
    ExampleActionTypes.ADD_ITEM_START_EXAMPLE,
    addItem,
  );
}

export function* onDeleteStart() {
  yield takeLatest(
    ExampleActionTypes.DELETE_ITEM_START_EXAMPLE,
    deleteItem,
  );
}

export function* onSearchStart() {
  yield takeLatest(
    ExampleActionTypes.SEARCH_START_EXAMPLE,
    fetchListWithSearch,
  );
}

export function* exampleSagas() {
  yield all([
    call(onFetchListStart),
    call(onAddStart),
    call(onUpdateItemStart),
    call(onDeleteStart),
    call(onSearchStart),
  ]);
}
