import EventActionTypes from './event.types';
const INITIAL_STATE = {
  isLoading: false,
  isLoadingEventAdrress: false,
  listEventToken: [],
  event: {},
  error: '',
};

const eventReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case EventActionTypes.FETCH_EVENT_INFO:
      return {
        ...state,
        isLoading: true,
      };
    case EventActionTypes.FETCH_EVENT_INFO_SUCCESS:
      return {
        ...state,
        isLoading: false,
        event: action.payload,
      };
    case EventActionTypes.FETCH_EVENT_INFO_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case EventActionTypes.FETCH_EVENT_FROM_ADDRESS:
      return {
        ...state,
        isLoadingEventAdrress: true,
      };
    case EventActionTypes.FETCH_EVENT_FROM_ADDRESS_SUCCESS:
      return {
        ...state,
        isLoadingEventAdrress: false,
        listEventToken: action.payload,
      };
    case EventActionTypes.FETCH_EVENT_FROM_ADDRESS_FAILURE:
      return {
        ...state,
        isLoadingEventAdrress: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default eventReducer;
