import React, { useRef, useEffect, useState } from 'react';

import './footer.styles.scss';

import BankHouse from 'src/assets/img/timeless_logo.svg';
import AppleDownload from 'src/assets/img/AppstoreDownload.svg';
import TimelessLogo from 'src/assets/img/TimelessLogo.svg';

const FooterGallery = ({ setHeightFooter }) => {
  const [isMobile, setIsMobile] = useState(false);
  window.onresize = () => {
    if (window.innerWidth < 768) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };
  useEffect(() => {
    if (window.innerWidth < 768) {
      setIsMobile(true);
    }
  }, []);
  const iOSUrl =
    'https://apps.apple.com/us/app/timeless-calendar/id1459942576';
  return (
    <div className="footer-container">
      <div className="left-footer">
        <img src={TimelessLogo} />
        <div className="text">#MakeItTimeless</div>
      </div>
      <div className="center-footer">
        Be there. Digitize and preserve memory as NFT
        collectibles.
      </div>
      <div
        className="right-footer"
        style={{ width: isMobile ? '100%' : 'unset' }}
      >
        <a
          href="https://timeless.space/app"
          target="_blank"
          rel="noreferrer"
        >
          {isMobile ? (
            'Install Timeless app'
          ) : (
            <img src={AppleDownload} />
          )}
        </a>
      </div>
    </div>
  );
};

export default FooterGallery;
