import React, { useState } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
// @material-ui/core components
// core components
// import Navbar from '../components/Navbars/Navbar.js';
import Sidebar from 'src/components/sidebar/sidebar.component';

import routes from 'src/routes.js';
import './Pages.scss';
import 'simplebar/dist/simplebar.min.css';

const routesWithEnvs = [];
routes.forEach((prop, idx) => {
  if (prop.layout === '/') {
    routesWithEnvs.push(
      <Route
        path={prop.path}
        key={idx}
        exact
        render={(props) => (
          <prop.component
            {...props}
            envRoute={'production'}
          />
        )}
      />,
    );
    routesWithEnvs.push(
      <Route
        path={`/dev${prop.path}`}
        key={`${idx}-dev`}
        exact
        render={(props) => (
          <prop.component {...props} envRoute={'dev'} />
        )}
      />,
    );
    routesWithEnvs.push(
      <Route
        path={`/staging${prop.path}`}
        key={`${idx}-staging`}
        exact
        render={(props) => (
          <prop.component {...props} envRoute={'staging'} />
        )}
      />,
    );
  }
});

const switchRoutes = (
  <Switch>
    {routesWithEnvs}
    <Redirect from="/" />
  </Switch>
);

export default function Admin() {
  return (
    <div className="pages">
      <div className="container-page">{switchRoutes}</div>
    </div>
  );
}
