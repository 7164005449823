import { isValidChecksumAddress } from 'ethereumjs-util';
import { bech32 } from 'bech32';

export function checkAddressValidation(address) {
  if (!address) {
    return false;
  }
  if (address.startsWith('one1')) {
    try {
      bech32.decode(address);
      return true;
    } catch (error) {
      return false;
    }
  } else {
    return isValidChecksumAddress(address);
  }
}
