import {
  takeLatest,
  put,
  all,
  call,
  select,
} from 'redux-saga/effects';

import UserActionTypes from './user.types';
import Swal from 'sweetalert2';

import {
  checkInStart,
  checkInSuccess,
  checkInFailure,
  signInSuccess,
  signInFailure,
  signOutSuccess,
  signOutFailure,
  signUpSuccess,
  signUpFailure,
} from './user.actions';

import { fetchEventFromAddressSuccess } from '../event/event.action';

import {
  authLogin,
  authSession,
  checkInAPI,
} from '../../api/auth-api';
import { getEventFromAddressAPI } from '../../api/event-api';
import Cookies from '../../utils/cookies';
import Toast from '../../utils/toast';
import { selectEventInfo } from '../event/event.selector';

export function* checkInToken(action) {
  try {
    const { env } = action;
    const res = yield checkInAPI(action, env);
    const data = yield getEventFromAddressAPI(
      action.payload,
      action.env,
    );
    yield put(fetchEventFromAddressSuccess(data.data));
    yield put(checkInSuccess(res));
    if (env !== 'production') {
      action.history.push(
        `/${env}/gallery/${action.payload}`,
      );
    } else {
      action.history.push(`/gallery/${action.payload}`);
    }
  } catch (error) {
    yield put(checkInFailure(error));
  }
}

export function* onCheckInStart() {
  yield takeLatest(
    UserActionTypes.CHECK_IN_START,
    checkInToken,
  );
}

export function* getSnapshotFromUserAuth(
  userAuth,
  additionalData,
) {
  try {
    // const userRef = yield call(
    //   createUserProfileDocument,
    //   userAuth,
    //   additionalData
    // );
    // const userSnapshot = yield userRef.get();
    // yield put(
    //   signInSuccess({
    //     id: userSnapshot.id,
    //     ...userSnapshot.data()
    //   })
    // );
  } catch (error) {
    yield put(signInFailure(error));
  }
}

export function* signInWithGoogle() {
  try {
    // const { user } = yield auth.signInWithPopup(
    //   googleProvider
    // );
    // yield getSnapshotFromUserAuth(user);
  } catch (error) {
    yield put(signInFailure(error));
  }
}

export function* signInWithEmail({
  payload: { email, password },
}) {
  try {
    // const { user } = yield auth.signInWithEmailAndPassword(
    //   email,
    //   password
    // );
    // yield getSnapshotFromUserAuth(user);
  } catch (error) {
    yield put(signInFailure(error));
  }
}

export function* signInWithUsernamePassword({
  payload: { username, password },
}) {
  try {
    const loginData = yield authLogin({
      username,
      password,
    });

    yield put(signInSuccess(loginData.data));

    Toast.fire({
      icon: 'success',
      title: 'Signed in successfully',
    });
  } catch (error) {
    yield put(signInFailure(error.response.data));
  }
}

export function* isUserAuthenticated() {
  try {
    if (!Cookies.get('token')) {
      return;
    }
    const userAuth = yield authSession();
    if (!userAuth) return;
    yield put(signInSuccess(userAuth.data));
  } catch (error) {
    yield put(signInFailure(error.response.data));
  }
}

export function* signOut() {
  try {
    // yield auth.signOut();
    yield put(signOutSuccess());
  } catch (error) {
    yield put(signOutFailure(error));
  }
}

export function* signUp({
  payload: { email, password, displayName },
}) {
  try {
    // const {
    //   user
    // } = yield auth.createUserWithEmailAndPassword(
    //   email,
    //   password
    // );
    // yield put(
    //   signUpSuccess({
    //     user,
    //     additionalData: { displayName }
    //   })
    // );
  } catch (error) {
    yield put(signUpFailure(error));
  }
}

export function* signInAfterSignUp({
  payload: { user, additionalData },
}) {
  yield getSnapshotFromUserAuth(user, additionalData);
}

export function* onGoogleSignInStart() {
  yield takeLatest(
    UserActionTypes.GOOGLE_SIGN_IN_START,
    signInWithGoogle,
  );
}

export function* onEmailSignInStart() {
  yield takeLatest(
    UserActionTypes.EMAIL_SIGN_IN_START,
    signInWithEmail,
  );
}

export function* onCheckUserSession() {
  yield takeLatest(
    UserActionTypes.CHECK_USER_SESSION,
    isUserAuthenticated,
  );
}

export function* onSignOutStart() {
  yield takeLatest(UserActionTypes.SIGN_OUT_START, signOut);
}

export function* onSignUpStart() {
  yield takeLatest(UserActionTypes.SIGN_UP_START, signUp);
}

export function* onSignUpSuccess() {
  yield takeLatest(
    UserActionTypes.SIGN_UP_SUCCESS,
    signInAfterSignUp,
  );
}

export function* onSignInStart() {
  yield takeLatest(
    UserActionTypes.SIGN_IN_START,
    signInWithUsernamePassword,
  );
}

export function* userSagas() {
  yield all([
    call(onGoogleSignInStart),
    call(onEmailSignInStart),
    call(onCheckUserSession),
    call(onSignOutStart),
    call(onSignUpStart),
    call(onSignUpSuccess),
    call(onSignInStart),
    call(onCheckInStart),
  ]);
}
