export function handle3DotsIntoBetween(value) {
  if (value) {
    let limit = 11;
    let dots = '...';
    if (value.length > limit) {
      // you can also use substr instead of substring
      value =
        value.substring(0, 6) +
        dots +
        value.substring(value.length - 5);
    }
  }
  return value;
}
