import EventActionTypes from './event.types';

export const fetchEventInfo = (data) => {
  return {
    type: EventActionTypes.FETCH_EVENT_INFO,
    payload: data.idEvent,
    history: data.history,
    env: data.env,
  };
};

export const fetchEventSuccess = (data) => ({
  type: EventActionTypes.FETCH_EVENT_INFO_SUCCESS,
  payload: data,
});

export const fetchEventFailure = (error) => ({
  type: EventActionTypes.FETCH_EVENT_INFO_FAILURE,
  payload: error,
});

export const fetchEventFromAddress = (data) => {
  return {
    type: EventActionTypes.FETCH_EVENT_FROM_ADDRESS,
    payload: data,
  };
};

export const fetchEventFromAddressSuccess = (data) => ({
  type: EventActionTypes.FETCH_EVENT_FROM_ADDRESS_SUCCESS,
  payload: data,
});

export const fetchEventFromAddressFailure = (error) => ({
  type: EventActionTypes.FETCH_EVENT_FROM_ADDRESS_FAILURE,
  payload: error,
});

export const checkIsExistAddress = (data) => {
  return {
    type: EventActionTypes.CHECK_IS_EXIST_ADDRESS,
    payload: data,
  };
};
