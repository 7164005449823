import ExampleActionTypes from './example.types';

export const fetchListStart = (params) => ({
  type: ExampleActionTypes.FETCH_LIST_START_EXAMPLE,
  payload: params,
});

export const fetchListSuccess = (list) => ({
  type: ExampleActionTypes.FETCH_LIST_SUCCESS_EXAMPLE,
  payload: list,
});

export const fetchListFailure = (error) => ({
  type: ExampleActionTypes.FETCH_LIST_FAILURE_EXAMPLE,
  payload: error,
});

export const openAdd = () => ({
  type: ExampleActionTypes.OPEN_ADD_EXAMPLE,
});

export const openEdit = (item) => ({
  type: ExampleActionTypes.OPEN_EDIT_EXAMPLE,
  payload: item,
});

export const openDelete = (id) => ({
  type: ExampleActionTypes.OPEN_DELETE_EXAMPLE,
  payload: id,
});

export const closeAdd = () => ({
  type: ExampleActionTypes.CLOSE_ADD_EXAMPLE,
});

export const closeEdit = () => ({
  type: ExampleActionTypes.CLOSE_EDIT_EXAMPLE,
});

export const closeDelete = () => ({
  type: ExampleActionTypes.CLOSE_DELETE_EXAMPLE,
});

export const addItemStart = (item) => ({
  type: ExampleActionTypes.ADD_ITEM_START_EXAMPLE,
  payload: item,
});

export const addItemSuccess = () => ({
  type: ExampleActionTypes.ADD_ITEM_SUCCESS_EXAMPLE,
});

export const addItemFailure = (error) => ({
  type: ExampleActionTypes.ADD_ITEM_FAILURE_EXAMPLE,
  payload: error,
});

export const editItemStart = (item) => ({
  type: ExampleActionTypes.EDIT_ITEM_START_EXAMPLE,
  payload: item,
});

export const editItemSuccess = (item) => ({
  type: ExampleActionTypes.EDIT_ITEM_SUCCESS_EXAMPLE,
  payload: item,
});

export const editItemFailure = (error) => ({
  type: ExampleActionTypes.EDIT_ITEM_FAILURE_EXAMPLE,
  payload: error,
});

export const deleteItemStart = (id) => ({
  type: ExampleActionTypes.DELETE_ITEM_START_EXAMPLE,
  payload: id,
});
export const deleteItemSuccess = () => ({
  type: ExampleActionTypes.DELETE_ITEM_SUCCESS_EXAMPLE,
});

export const deleteItemFailure = (error) => ({
  type: ExampleActionTypes.DELETE_ITEM_FAILURE_EXAMPLE,
  payload: error,
});

export const putParamsFetch = (params) => ({
  type: ExampleActionTypes.PUT_PARAMS_FETCH_EXAMPLE,
  payload: params,
});

export const fetchSearch = (searchKey) => ({
  type: ExampleActionTypes.SEARCH_START_EXAMPLE,
  payload: searchKey,
});
