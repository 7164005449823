import UserActionTypes from './user.types';
import Cookies from '../../utils/cookies';
const INITIAL_STATE = {
  currentUser: null,
  error: null,
  isLogin: Cookies.get('token') ? true : false,
  isLoading: false,
  dataCheckIn: {},
  errorCheckIn: null,
  addressWallet: null,
};

const userReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UserActionTypes.SIGN_IN_SUCCESS:
      return {
        ...state,
        currentUser: action.payload,
        error: null,
        isLogin: true,
      };
    case UserActionTypes.SIGN_OUT_SUCCESS:
      Cookies.remove('token', { path: '/' });
      return {
        ...state,
        currentUser: null,
        error: null,
        isLogin: false,
      };
    case UserActionTypes.SIGN_IN_FAILURE:
    case UserActionTypes.SIGN_OUT_FAILURE:
    case UserActionTypes.SIGN_UP_FAILURE:
      Cookies.remove('token', { path: '/' });
      return {
        ...state,
        error: action.payload,
        isLogin: false,
      };
    case UserActionTypes.CHECK_IN_START:
      return {
        ...state,
        isLoading: true,
      };
    case UserActionTypes.CHECK_IN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        dataCheckIn: action.payload,
      };
    case UserActionTypes.CHECK_IN_FAILURE:
      return {
        ...state,
        isLoading: false,
        errorCheckIn: action.payload,
      };
    case UserActionTypes.SET_ADDRESS_WALLET:
      return {
        ...state,
        addressWallet: action.payload,
      };
    default:
      return state;
  }
};

export default userReducer;
