const ExampleActionTypes = {
  FETCH_LIST_START_EXAMPLE: 'FETCH_LIST_START_EXAMPLE',
  FETCH_LIST_SUCCESS_EXAMPLE: 'FETCH_LIST_SUCCESS_EXAMPLE',
  FETCH_LIST_FAILURE_EXAMPLE: 'FETCH_LIST_FAILURE_EXAMPLE',
  OPEN_EDIT_EXAMPLE: 'OPEN_EDIT_EXAMPLE',
  OPEN_ADD_EXAMPLE: 'OPEN_ADD_EXAMPLE',
  OPEN_DELETE_EXAMPLE: 'OPEN_DELETE_EXAMPLE',
  CLOSE_EDIT_EXAMPLE: 'CLOSE_EDIT_EXAMPLE',
  CLOSE_ADD_EXAMPLE: 'CLOSE_ADD_EXAMPLE',
  CLOSE_DELETE_EXAMPLE: 'CLOSE_DELETE_EXAMPLE',
  ADD_ITEM_START_EXAMPLE: 'ADD_ITEM_START_EXAMPLE',
  EDIT_ITEM_START_EXAMPLE: 'EDIT_ITEM_START_EXAMPLE',
  DELETE_ITEM_START_EXAMPLE: 'DELETE_ITEM_START_EXAMPLE',
  ADD_ITEM_SUCCESS_EXAMPLE: 'ADD_ITEM_SUCCESS_EXAMPLE',
  EDIT_ITEM_SUCCESS_EXAMPLE: 'EDIT_ITEM_SUCCESS_EXAMPLE',
  DELETE_ITEM_SUCCESS_EXAMPLE:
    'DELETE_ITEM_SUCCESS_EXAMPLE',
  ADD_ITEM_FAILURE_EXAMPLE: 'ADD_ITEM_FAILURE_EXAMPLE',
  EDIT_ITEM_FAILURE_EXAMPLE: 'EDIT_ITEM_FAILURE_EXAMPLE',
  DELETE_ITEM_FAILURE_EXAMPLE:
    'DELETE_ITEM_FAILURE_EXAMPLE',
  PUT_PARAMS_FETCH_EXAMPLE: 'PUT_PARAMS_FETCH_EXAMPLE',
  SEARCH_START_EXAMPLE: 'SEARCH_STAR_EXAMPLET',
};

export default ExampleActionTypes;
