import Axios from './custom-axios';
const axios = require('axios');

export function getEventInfo(eventId, env) {
  return Axios({
    method: 'GET',
    url: `https://api.timeless.space/${env}/moments/${eventId}`,
  }).then((res) => {
    return res;
  });
}

export function getEventFromAddressAPI(address, env) {
  return Axios({
    method: 'GET',
    url: `https://api.timeless.space/${env}/moments/wallet_address/${address}`,
  }).then((res) => res);
}
