import React, {
  useState,
  useEffect,
  useDispatch,
  useSelect,
} from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import './home-page.styles.scss';
import { useParams } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';

import Harmony from 'src/assets/img/harmony.svg';
import HarmonyLogo from 'src/assets/img/HarmonyLogo.svg';
import Close from 'src/assets/img/CloseIcon.svg';
import LogoWithBG from 'src/assets/img/HarmonyLogoWithBg.svg';
import QrCode from 'src/assets/img/sampleQR.svg';
import Loading from 'src/assets/img/icons/loading.gif';

import Header from '../../components/header/header.component';

import {
  selectIsLoading,
  selectEventInfo,
  selectEventError,
} from '../../redux/event/event.selector';

import {
  selectLoadingCheckin,
  selectDataCheckin,
  selectErrorCheckin,
} from '../../redux/user/user.selectors';

import { formatDate } from '../../utils/timeFormat';

import { checkAddressValidation } from '../../utils/checkValidation';

import {
  fetchEventInfo,
  fetchEventFromAddress,
} from '../../redux/event/event.action';
import {
  checkInStart,
  setAddress,
} from '../../redux/user/user.actions';

const HomePage = (props) => {
  const {
    envRoute,
    fetchEventInfo,
    isLoading,
    eventInfo,
    eventError,
    submitAddress,
    isLoadingCheckin,
    checkinData,
    errorCheckin,
    setAddressWallet,
    fetchEventsAdrress,
  } = props;

  const idEvent = useParams().id;
  const [
    isOpenWalletPopup,
    setIsOpenWalletPopup,
  ] = useState(false);
  const [address, setAddress] = useState('');
  const [
    showUnValidAddress,
    setshowUnValidAddress,
  ] = useState(false);
  const [isEnableCheckin, setIsEnableCheckin] = useState(
    false,
  );
  const [addressHolder, setAddressHolder] = useState(null);

  const event = eventInfo.data;

  useEffect(() => {
    fetchEventInfo({
      idEvent,
      history: props.history,
      env: envRoute,
    });
    if (!idEvent) {
      setIsOpenWalletPopup(true);
    }
  }, []);

  useEffect(() => {
    if (!event) return;
    let startCheckIn =
      new Date(event.time.start).getTime() -
      100 * 60 * 1000; // start time - 100 minutes
    let endCheckIn =
      new Date(event.time.end).getTime() + 24 * 3600 * 1000; // end time + 24 hours
    let now = Date.now();
    let shouldDisableCheckInNext = false;
    if (now >= endCheckIn) {
      // Nothing to do, event has passed check-in time frame
      return;
    } else if (now >= startCheckIn) {
      setIsEnableCheckin(true);
      shouldDisableCheckInNext = true;
    }
    let timer = setInterval(function () {
      now = Date.now();
      if (!shouldDisableCheckInNext) {
        if (now >= startCheckIn) {
          setIsEnableCheckin(true);
          shouldDisableCheckInNext = true;
        }
      } else {
        if (now >= endCheckIn) {
          setIsEnableCheckin(false);
          clearInterval(timer);
          timer = null;
        }
      }
    }, 5000);
    return () => {
      if (timer) clearInterval(timer);
    };
  }, [event]);

  const handleCheckin = () => {
    if (isEnableCheckin) {
      setIsOpenWalletPopup(!isOpenWalletPopup);
    }
  };

  const handleSubmitAddress = () => {
    if (!showUnValidAddress) {
      setAddressWallet(address);
      submitAddress({
        address,
        history: props.history,
        idEvent,
        env: envRoute,
      });
    }
  };

  function formatHideAddress(address, num = 6) {
    return `${address.substr(0, num)}...${address.substr(
      address.length - num,
      address.length,
    )}`;
  }

  function handleAddressHolder(event) {
    event.preventDefault();

    const type = event.type;
    const isBlur = type === 'blur';
    const isFocus = type === 'focus';
    const isClick = type === 'click';
    const value = address;
    const length = value.length;

    if (!type && !isFocus && !isBlur) return;

    if (isBlur && address && address.length >= 42) {
      setAddressHolder(formatHideAddress(address, 14));
      event.target.isScrollToEnd = false;
      return;
    }

    if (isClick && !event.target.isScrollToEnd) {
      event.target.setSelectionRange(length, length);
      event.target.scrollLeft = event.target.scrollWidth;
      event.target.isScrollToEnd = true;
    }

    setAddressHolder(null);
  }

  return (
    <div className="home-page">
      <Header
        openPopup={handleCheckin}
        isOpenWalletPopup={isOpenWalletPopup}
      />
      <div className="main-content">
        <CSSTransition
          in={!isOpenWalletPopup}
          timeout={1000}
          classNames="home-fade"
          unmountOnExit
        >
          <div className="infor-card">
            <div className="event-part">
              <div className="title">
                {' '}
                {
                  event?.feedSchemaData?.headline
                    .headlineText
                }{' '}
              </div>
              <div className="time">
                {formatDate(event?.time)}
              </div>
              <div className="img-container infor-img">
                {event ? (
                  <img src={event?.flair.path} />
                ) : null}
              </div>
              <div className="subtitle">{event?.title}</div>
              <div className="detail">
                {event?.location}
              </div>
            </div>
            <div
              style={{
                pointerEvents: !isEnableCheckin && 'none',
                opacity: !isEnableCheckin && '0.6',
                // color: !isEnableCheckin && 'gray',
                // background: !isEnableCheckin && '#151112',
              }}
              className="checkin-button"
              onClick={handleCheckin}
            >
              <img src={Harmony} className="icon-wrapper" />
              <div className="text"> Check In </div>
            </div>
            <div className="extra-text">
              {!isEnableCheckin
                ? 'Check-in available 90 min prior to the event start'
                : 'Check-in. Capture the moment as NFT collectibles'}
            </div>
            <div className="qr-part">
              <div className="qr-content">
                <img src={QrCode} />
              </div>
              <div className="text">
                <div className="title">
                  Join Fellow Harmonauts
                </div>
                <div className="content">
                  iOS user? Scan to download Timeless - join
                  this event, subscribe to future Harmony
                  events, and collect NFT drops.
                </div>
              </div>
            </div>
          </div>
        </CSSTransition>

        <CSSTransition
          in={isOpenWalletPopup}
          timeout={1000}
          classNames="home-fade"
          unmountOnExit
        >
          <div className="infor-card">
            <div className="title-wallet-popup">
              <img src={HarmonyLogo} />
              &nbsp;Harmony
            </div>
            <div className="main-wallet-popup">
              <img src={Close} onClick={handleCheckin} />
              <div className="title">Welcome Harmonaut</div>
              <div className="brief">
                Capture the moment - browse, sell, gift, and
                print NFT into IRL collectibles, all with
                Harmony $ONE.
              </div>
              <div className="img-container">
                <img src={LogoWithBG} />
              </div>
              <div
                style={{
                  visibility: !showUnValidAddress
                    ? 'hidden'
                    : 'visible',
                }}
                className="error-message"
              >
                Invalid wallet address
              </div>
              <input
                className="input-container"
                placeholder="One wallet address"
                value={addressHolder || address}
                onChange={(e) => {
                  setAddress(e.target.value);
                  if (
                    checkAddressValidation(e.target.value)
                  ) {
                    setshowUnValidAddress(false);
                  } else {
                    setshowUnValidAddress(true);
                  }
                }}
                onClick={handleAddressHolder}
                onFocus={handleAddressHolder}
                onBlur={handleAddressHolder}
              />
              <div className="other-options">
                <span>No wallet?&nbsp;</span>
                <a
                  href="https://1wallet.crazy.one/create"
                  target="blank"
                >
                  Create
                </a>
                <span>&nbsp;$one!</span>
              </div>
              <div
                className="browse-button"
                style={{
                  background:
                    !showUnValidAddress &&
                    address != '' &&
                    '#69FABD',
                  opacity:
                    !showUnValidAddress &&
                    address != '' &&
                    1,
                }}
                onClick={handleSubmitAddress}
              >
                <img
                  src={Loading}
                  width="17px"
                  style={{
                    display: !isLoadingCheckin && 'none',
                  }}
                />
                &nbsp;&nbsp;
                {idEvent
                  ? 'Check In and Browse'
                  : 'Browse Collection'}
              </div>
            </div>
            <div className="extra-text popup">
              A commemorative NFT will be dropped into the
              address specified above. We do not own your
              private keys and cannot access your funds
              without your confirmation.
            </div>
          </div>
        </CSSTransition>
      </div>{' '}
      <div className="footer" />
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  isLoading: selectIsLoading,
  eventInfo: selectEventInfo,
  eventError: selectEventError,
  isLoadingCheckin: selectLoadingCheckin,
  checkinData: selectDataCheckin,
  errorCheckin: selectErrorCheckin,
});

const mapDispatchToProps = (dispatch) => ({
  fetchEventInfo: (idEvent) =>
    dispatch(fetchEventInfo(idEvent)),
  submitAddress: (address) =>
    dispatch(checkInStart(address)),
  setAddressWallet: (address) =>
    dispatch(setAddress(address)),
  fetchEventsAdrress: (address) =>
    dispatch(fetchEventFromAddress(address)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(HomePage);
