import ExampleActionTypes from './example.types';
const INITIAL_STATE = {
  list: [],
  itemEdit: {},
  idDelete: null,
  isLoadingList: false,
  isLoadingAdd: false,
  isLoadingEdit: false,
  isLoadingDelete: false,
  error: null,
  errorAdd: null,
  errorEdit: null,
  errorDelete: null,
  isOpenAdd: false,
  isOpenEdit: false,
  isOpenDelete: false,
  paramsFetchList: {
    current: 1,
    pageSize: 10,
  },
};

const exampleReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ExampleActionTypes.FETCH_LIST_START_EXAMPLE:
      return {
        ...state,
        isLoadingList: true,
      };
    case ExampleActionTypes.FETCH_LIST_SUCCESS_EXAMPLE:
      return {
        ...state,
        isLoadingList: false,
        list: action.payload,
      };
    case ExampleActionTypes.FETCH_LIST_FAILURE_EXAMPLE:
      return {
        ...state,
        isLoadingList: false,
        error: action.payload,
      };
    case ExampleActionTypes.ADD_ITEM_START_EXAMPLE:
      return {
        ...state,
        isLoadingAdd: true,
      };
    case ExampleActionTypes.ADD_ITEM_SUCCESS_EXAMPLE:
      return {
        ...state,
        errorAdd: null,
        isLoadingAdd: false,
        isOpenAdd: false,
      };
    case ExampleActionTypes.ADD_ITEM_FAILURE_EXAMPLE:
      return {
        ...state,
        errorAdd: action.payload,
        isLoadingAdd: false,
      };
    case ExampleActionTypes.EDIT_ITEM_START_EXAMPLE:
      return {
        ...state,
        isLoadingEdit: true,
      };
    case ExampleActionTypes.EDIT_ITEM_SUCCESS_EXAMPLE:
      return {
        ...state,
        errorEdit: null,
        list: {
          ...state.list,
          data: state.list.data.map((item) =>
            item._id === action.payload._id
              ? { ...item, ...action.payload }
              : item,
          ),
        },
        isLoadingEdit: false,
        isOpenEdit: false,
      };
    case ExampleActionTypes.EDIT_ITEM_FAILURE_EXAMPLE:
      return {
        ...state,
        errorEdit: action.payload,
        isLoadingEdit: false,
        isOpenEdit: false,
      };
    case ExampleActionTypes.DELETE_ITEM_START_EXAMPLE:
      return {
        ...state,
        isLoadingDelete: true,
      };
    case ExampleActionTypes.DELETE_ITEM_SUCCESS_EXAMPLE:
      return {
        ...state,
        isLoadingDelete: false,
        isOpenDelete: false,
      };
    case ExampleActionTypes.DELETE_ITEM_FAILURE_EXAMPLE:
      return {
        ...state,
        isLoadingDelete: false,
      };
    case ExampleActionTypes.OPEN_ADD_EXAMPLE:
      return {
        ...state,
        isOpenAdd: true,
      };
    case ExampleActionTypes.OPEN_EDIT_EXAMPLE:
      return {
        ...state,
        itemEdit: action.payload,
        isOpenEdit: true,
      };
    case ExampleActionTypes.OPEN_DELETE_EXAMPLE:
      return {
        ...state,
        isOpenDelete: true,
        idDelete: action.payload,
      };
    case ExampleActionTypes.CLOSE_ADD_EXAMPLE:
      return {
        ...state,
        isOpenAdd: false,
      };
    case ExampleActionTypes.CLOSE_EDIT_EXAMPLE:
      return {
        ...state,
        isOpenEdit: false,
      };
    case ExampleActionTypes.CLOSE_DELETE_EXAMPLE:
      return {
        ...state,
        isOpenDelete: false,
      };
    case ExampleActionTypes.PUT_PARAMS_FETCH_EXAMPLE:
      return {
        ...state,
        paramsFetchList: action.payload,
      };
    default:
      return state;
  }
};

export default exampleReducer;
