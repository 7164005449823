import Axios from './custom-axios';
export function getExamples(params = {}) {
  return Axios({
    method: 'GET',
    url: '/examples',
    params: params,
  }).then((res) => {
    return res.data;
  });
}

export function getExamplesAdditionForPicture() {
  return Axios({
    method: 'GET',
    url: '/examples?select=name',
  }).then((res) => {
    return res.data;
  });
}

export function addExample(body) {
  return Axios({
    method: 'POST',
    url: '/examples',
    data: body,
  }).then((res) => {
    return res.data;
  });
}

export function updateExample(id, body) {
  return Axios({
    method: 'PUT',
    url: `/examples/${id}`,
    data: body,
  }).then((res) => {
    return res.data;
  });
}

export function deleteExample(id) {
  return Axios({
    method: 'DELETE',
    url: `/examples/${id}`,
  }).then((res) => {
    return res.data;
  });
}

export function uploadImage(file) {
  const formData = new FormData();
  formData.append('pictureFile', file);
  return Axios({
    method: 'POST',
    url: `/examples/upload`,
    data: formData,
  }).then((res) => {
    return res.data;
  });
}
