import {
  takeLatest,
  call,
  put,
  all,
  select,
} from 'redux-saga/effects';

import {
  getEventInfo,
  getEventFromAddressAPI,
} from '../../api/event-api';

import EventActionTypes from './event.types';

import Toast from '../../utils/toast';

import {
  fetchEventInfo,
  fetchEventSuccess,
  fetchEventFailure,
  fetchEventFromAddressSuccess,
  fetchEventFromAddressFailure,
  fetchEventFromAddress,
} from './event.action';

// import { selectParamsFetch } from './example.selectors';

export function* fetchEventInfos(action) {
  const { env } = action;
  try {
    const res = yield getEventInfo(action.payload, env);
    yield put(fetchEventSuccess(res));
  } catch (error) {
    if (env !== 'production') {
      action.history.push(`/${env}`);
    } else {
      action.history.push('/');
    }
    yield put(fetchEventFailure(error.response.data));
  }
}

export function* fetchEventAddress(action) {
  try {
    const { env } = action.payload;
    const res = yield getEventFromAddressAPI(
      action.payload.address,
      env,
    );
    // if (env !== 'production') {
    //   action.payload.history.push(
    //     `/${env}/gallery/${action.payload.address}`,
    //   );
    // } else {
    //   action.payload.history.push(
    //     `/gallery/${action.payload.address}`,
    //   );
    // }
    yield put(fetchEventFromAddressSuccess(res.data));
  } catch (error) {
    yield put(fetchEventFromAddressFailure(error));
  }
}

export function* checkIsExistAddress(action) {
  try {
    const { env } = action.payload;
    const res = yield getEventFromAddressAPI(
      action.payload.address,
      env,
    );
    yield put(fetchEventFromAddressSuccess(res.data));
    if (env !== 'production') {
      action.payload.history.push(
        `/${env}/gallery/${action.payload.address}`,
      );
    } else {
      action.payload.history.push(
        `/gallery/${action.payload.address}`,
      );
    }
  } catch (error) {
    yield put(fetchEventFromAddressFailure(error));
  }
}

export function* onFetchEventStart() {
  yield takeLatest(
    EventActionTypes.FETCH_EVENT_INFO,
    fetchEventInfos,
  );
}

export function* onfetchEventAddressStart() {
  yield takeLatest(
    EventActionTypes.FETCH_EVENT_FROM_ADDRESS,
    fetchEventAddress,
  );
}

export function* onCheckIsExistAddress() {
  yield takeLatest(
    EventActionTypes.CHECK_IS_EXIST_ADDRESS,
    checkIsExistAddress,
  );
}

export function* eventSagas() {
  yield all([
    call(onFetchEventStart),
    call(onfetchEventAddressStart),
    call(onCheckIsExistAddress),
  ]);
}
