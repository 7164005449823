import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import SingleCard from '../single-card/single-card.component';
import CardImage1 from 'src/assets/img/card/card-1.jpeg';
import CardImage2 from 'src/assets/img/card/card-2.jpeg';
import CardImage3 from 'src/assets/img/card/card-3.jpeg';
import CardImage4 from 'src/assets/img/card/card-4.jpeg';
import CardImage5 from 'src/assets/img/card/card-5.jpeg';
import LockedGridImage from 'src/assets/img/card/LockedGrid.png';
import $ from 'jquery';
import {
  InfoCircleOutlined,
  CloseCircleOutlined,
  LeftOutlined,
  RightOutlined,
} from '@ant-design/icons';
import './list-card.styles.scss';
import LogoWithBG from 'src/assets/img/HarmonyLogoWithBg.svg';
import { selectAddressEventList } from '../../../redux/event/event.selector';
import { fetchEventFromAddress } from '../../../redux/event/event.action';
import { useHistory, useParams } from 'react-router';
import moment from 'moment';

const iconStyles = {
  color: '#fff',
  fontSize: 24,
  borderRadius: '50%',
  border: 'none',
};
const listData = [
  {
    dummy: true,
    id: 1,
    image:
      'https://res.cloudinary.com/timeless/image/upload/v1/app/event_cards/Harmony/yellowcab.gif',
    title: 'Join us @NFT.NYC | Nov 1 - 4',
    location:
      'Join us at Time Square and collect the next set of commemorative NFTs.',
  },

  // {
  //   dummy: true,
  //   id: 3,
  //   image:
  //     'https://res.cloudinary.com/timeless/image/upload/v1/app/event_cards/Harmony/yellowcab.gif',
  //   title: 'Join us @NFT.NYC | Nov 1 - 4',
  //   location:
  //     'Join us at Time Square and collect the next set of commemorative NFTs.',
  // },
  // {
  //   dummy: true,
  //   id: 2,
  //   image:
  //     'https://res.cloudinary.com/timeless/image/upload/v1/app/event_cards/Harmony/yellowcab.gif',
  //   title: 'Join us @NFT.NYC | Nov 1 - 4',
  //   location:
  //     'Join us at Time Square and collect the next set of commemorative NFTs.',
  // },
];

let listScale = [1, 0.86, 0.78];
for (let i = 2; i < 30; i++) {
  listScale.push(
    listScale[i] -
      ((listScale[i - 1] - listScale[i]) * 2) / 3,
  );
}

const ListCard = (props) => {
  const { addressEventList, envRoute } = props;
  const [cardStyles, setCardStyles] = useState([]);
  console.log('addressEventList', addressEventList);
  const [listCardData, setListCardData] = useState([
    ...addressEventList.map((item) => ({
      id: item.event.uid,
      image: item.event.image.path,
      title: item.event.title,
      location: item.event.location,
      notes: item.event.notes,
      time: item.event.time,
      contractAddress: item.contractAddress,
    })),
    ...listData,
  ]);

  const getCardStylesFromIdx = (lenList, enteredIdx) => {
    let numbOrders = [];
    for (let i = 0; i < lenList; i++) {
      numbOrders.push(0);
    }
    numbOrders[enteredIdx] = 0;
    let numb = 1;
    for (let i = enteredIdx - 1; i >= 0; i--) {
      numbOrders[i] = numb;
      numb++;
    }
    numb = 1;
    for (let i = enteredIdx + 1; i < lenList; i++) {
      numbOrders[i] = numb;
      numb++;
    }
    let customCardStyles = [];

    // calc space
    const wItem = $('#list-card').width() / lenList + 226;
    const spaces = [];
    for (let i = 0; i < enteredIdx; i++) {
      spaces.push(
        -(wItem - wItem * listScale[numbOrders[i]]) / 2,
      );
    }
    spaces.push(0);
    for (let i = enteredIdx + 1; i < lenList; i++) {
      spaces.push(
        (wItem - wItem * listScale[numbOrders[i]]) / 2,
      );
    }
    for (let i = 0; i < lenList; i++) {
      customCardStyles.push({
        singleCard: {
          transform: `translate3d(${
            spaces[i]
          }px, 0px, 0px) scale3d(${
            listScale[numbOrders[i]]
          }, ${listScale[numbOrders[i]]}, 1)`,
          zIndex: 100 - numbOrders[i],
        },
        opBlack: {
          opacity:
            0 +
            (numbOrders[i] !== 0 ? numbOrders[i] / 10 : 0),
        },
      });
    }
    return customCardStyles;
  };
  const getCardStylesPropsFromIdx = (
    lenList,
    enteredIdx,
  ) => {
    let numbOrders = [];
    for (let i = 0; i < lenList; i++) {
      numbOrders.push(0);
    }
    numbOrders[enteredIdx] = 0;
    let numb = 1;
    for (let i = enteredIdx - 1; i >= 0; i--) {
      numbOrders[i] = numb;
      numb++;
    }
    numb = 1;
    for (let i = enteredIdx + 1; i < lenList; i++) {
      numbOrders[i] = numb;
      numb++;
    }
    let customCardStyles = [];

    // calc space
    const wItem = $('#list-card').width() / lenList + 226;
    const spaces = [];
    for (let i = 0; i < enteredIdx; i++) {
      spaces.push(
        -(wItem - wItem * listScale[numbOrders[i]]) / 2,
      );
    }
    spaces.push(0);
    for (let i = enteredIdx + 1; i < lenList; i++) {
      spaces.push(
        (wItem - wItem * listScale[numbOrders[i]]) / 2,
      );
    }
    for (let i = 0; i < lenList; i++) {
      customCardStyles.push({
        x: spaces[i],
        scale: listScale[numbOrders[i]],
        opacity:
          0 +
          (numbOrders[i] !== 0 ? numbOrders[i] / 10 : 0),
        zIndex: 100 - numbOrders[i],
      });
    }
    return customCardStyles;
  };
  useEffect(() => {
    $('#list-card')
      .on('mouseenter', '.container-card-img', function () {
        const enteredIdx = parseInt(
          $(this).children('.card-img').attr('data-idx'),
        );
        let customCardStyles = getCardStylesFromIdx(
          lenList,
          enteredIdx,
        );
        setCardStyles(customCardStyles);
      })
      .on(
        'mouseleave',
        '.container-card-img',
        function () {},
      );
    var xDown = null;
    var yDown = null;
    function getTouches(evt) {
      return (
        evt.touches || evt.originalEvent.touches // browser API
      ); // jQuery
    }
    var offset = [0, 0];

    function handleTouchStart(evt) {
      const firstTouch = getTouches(evt)[0];
      xDown = firstTouch.clientX;
      yDown = firstTouch.clientY;
    }

    function handleTouchMove(evt) {
      if (!xDown || !yDown) {
        return;
      }

      var xUp = evt.touches[0].clientX;
      var yUp = evt.touches[0].clientY;

      var xDiff = xDown - xUp;
      var yDiff = yDown - yUp;
      offset[0] = evt.touches[0].clientX + xDiff;
      $(this)
        .parent()
        .css(
          'transform',
          `translate3d(${offset[0]}px,0px,0px) scale3d(1,1,1)`,
        );
      $(this).parent().css('color', 'red');
      if (Math.abs(xDiff) > Math.abs(yDiff)) {
        /*most significant*/
        if (xDiff > 0) {
          // $('#list-card .single-card').eq(0).fadeOut(300);
          // setTimeout(() => {
          //   $('#list-card .single-card').eq(0).fadeIn(0);
          //   let newOrder = [...refListCardData.current];
          //   let firstEl = newOrder.shift();
          //   newOrder.push(firstEl);
          //   // console.log('newOrder', newOrder);
          //   setListCardData(newOrder);
          // }, 150);
        } else {
          /* right swipe */
        }
      } else {
        if (yDiff > 0) {
          /* down swipe */
        } else {
          /* up swipe */
        }
      }
      /* reset values */
      xDown = null;
      yDown = null;
    }
    const lenList = listCardData.length;
    let customCardStyles = getCardStylesFromIdx(lenList, 0);
    setCardStyles(customCardStyles);
    // $('#list-card').on(
    //   'touchstart',
    //   '.container-card-img',
    //   handleTouchStart,
    // );
    // $('#list-card').on(
    //   'touchmove',
    //   '.container-card-img',
    //   handleTouchMove,
    // );
    var isDown = false;

    // $('#list-card').on(
    //   'touchdown',
    //   '.container-card-img',
    //   function (e) {
    //     isDown = true;
    //     console.log('downnn');
    //     offset = [
    //       $(this).parent().offsetLeft - e.clientX,
    //       $(this).parent().offsetTop - e.clientY,
    //     ];
    //   },
    // );
    // $('#list-card').on(
    //   'touchmove',
    //   '.container-card-img',
    //   function (e) {
    //     e.preventDefault();
    //     console.log('parent', $(this).parent());
    //     var xUp = e.touches[0].clientX;
    //     $(this)
    //       .parent()
    //       .css(
    //         'transform',
    //         `translate3d(${
    //           e.clientX + offset[0] + 'px'
    //         },0px,0x) scale(1,1,1)`,
    //       );
    //     // $(this).parent().style.top =
    //     //   e.clientY + offset[1] + 'px';
    //   },
    // );
    var active = false;
    var currentX;
    var currentY;
    var initialX;
    var initialY;
    var xOffset = 0;
    var yOffset = 0;
    var cardStyleProps = [];

    $('#list-card').on(
      'touchstart',
      '.container-card-img',
      function (e) {
        if (e.type === 'touchstart') {
          initialX = e.touches[0].clientX - xOffset;
          initialY = e.touches[0].clientY - yOffset;
        } else {
          initialX = e.clientX - xOffset;
          initialY = e.clientY - yOffset;
        }
        active = true;
      },
    );
    const maxLeftToSwipe = 150;

    $('#list-card').on(
      'touchend',
      '.container-card-img',
      function (e) {
        initialX = currentX;
        initialY = currentY;
        active = false;
        if (-xOffset < maxLeftToSwipe) {
          changeTransformList(0);
          xOffset = 0;
        } else {
          let newOrder = [...refListCardData.current];
          let firstEl = newOrder.shift();
          newOrder.push(firstEl);
          // console.log('newOrder', newOrder);
          setListCardData(newOrder);
          changeTransformList(0);
          xOffset = 0;
        }
      },
    );
    function changeTransformList(x) {
      const defautCardStyles = getCardStylesPropsFromIdx(
        lenList,
        0,
      );
      const wItem = $('#list-card').width() / lenList;
      $('#list-card .single-card').each(function (index) {
        if (index == 0 && x <= 0) {
          $(this).css({
            transform: `translate3d(${x}px,0px,0px) scale3d(1,1,1)`,
            opacity: 1 + (x / maxLeftToSwipe) * 0.5,
          });
        } else {
          $(this).css({
            opacity: 1,
          });
          // const minus =
          //   listScale[index - 1] - listScale[index];
          // const scaleUnit =
          //   (1 / maxLeftToSwipe) * minus * Math.abs(x);
          // $('#list-card').width() / lenList;
          // if (-x < maxLeftToSwipe) {
          //   $(this).css({
          //     transform: `translate3d(${
          //       defautCardStyles[index].x +
          //       (x / maxLeftToSwipe) * wItem
          //     }px,0px,0px) scale3d(${
          //       defautCardStyles[index].scale + scaleUnit
          //     },${
          //       defautCardStyles[index].scale + scaleUnit
          //     },1)`,
          //     opacity: 1,
          //   });
          // }
        }
      });
    }
    $('#list-card').on(
      'touchmove',
      '.container-card-img',
      function (e) {
        if (active) {
          e.preventDefault();
          if (e.type === 'touchmove') {
            currentX = e.touches[0].clientX - initialX;
            currentY = e.touches[0].clientY - initialY;
          } else {
            currentX = e.clientX - initialX;
            currentY = e.clientY - initialY;
          }

          xOffset = currentX;
          yOffset = currentY;
          if (xOffset <= 0) {
            changeTransformList(xOffset);
          }
        }
      },
    );
  }, []);

  const refListCardData = useRef([]);
  useEffect(() => {
    refListCardData.current = listCardData;
  }, [listCardData]);

  // useEffect(() => {
  //   const lenList = listCardData.length;
  //   let customCardStyles = getCardStylesFromIdx(lenList, 0);
  //   setCardStyles(customCardStyles);
  // }, [listCardData]);

  const history = useHistory();
  const env = envRoute;
  const params = useParams();
  const test = true;
  let wContainerLCard = 700;
  if (listCardData.length == 1) {
    wContainerLCard = 480;
  }
  if (listCardData.length == 2) {
    wContainerLCard = 630;
  }
  if (listCardData.length == 3) {
    wContainerLCard = 840;
  }
  if (listCardData.length == 4) {
    wContainerLCard = 1030;
  }
  if (listCardData.length >= 5) {
    wContainerLCard = false;
  }
  return (
    <div
      className="container-lcard"
      style={{
        maxWidth: `${wContainerLCard}px`,
        margin: '0 auto',
      }}
    >
      <div id="list-card" className="list-card">
        {cardStyles.length &&
          listCardData.map((item, idx) => {
            return (
              <div
                key={item.id}
                className="single-card"
                style={cardStyles[idx].singleCard}
                onClick={() => {
                  if (item.dummy !== true) {
                    // history.push(
                    //   `/${env}/gallery-detail/${params.address}/${item.id}`,
                    // );
                  }
                }}
              >
                <div className="container-card-img">
                  <div className="btn-info">
                    {!item?.dummy &&
                      (item.isShowInfo ? (
                        <CloseCircleOutlined
                          style={iconStyles}
                          onClick={() => {
                            const newList = listCardData.map(
                              (itemCard) => {
                                if (
                                  itemCard.id === item.id
                                ) {
                                  return {
                                    ...itemCard,
                                    isShowInfo: false,
                                  };
                                }
                                return itemCard;
                              },
                            );
                            setListCardData(newList);
                          }}
                        />
                      ) : (
                        <InfoCircleOutlined
                          style={iconStyles}
                          onClick={() => {
                            const newList = listCardData.map(
                              (itemCard) => {
                                if (
                                  itemCard.id === item.id
                                ) {
                                  return {
                                    ...itemCard,
                                    isShowInfo: true,
                                  };
                                }
                                return itemCard;
                              },
                            );
                            setListCardData(newList);
                          }}
                        />
                      ))}
                  </div>
                  {!item?.dummy && item.isShowInfo ? (
                    <div className="info-detail">
                      <div className="box-title-sub">
                        <h3 className="title">
                          {item.title}
                        </h3>
                        <div
                          className="cus-text"
                          dangerouslySetInnerHTML={{
                            __html: item.notes,
                          }}
                        />
                      </div>
                      <div className="box-icon-harmony">
                        <img src={LogoWithBG} />
                        <p className="address">
                          {item.contractAddress.slice(
                            0,
                            10,
                          )}
                          {'...'}
                          {item.contractAddress.slice(-10)}
                        </p>
                      </div>
                      <div className="date">
                        <div className="label-date">
                          Date and Time
                        </div>
                        <div className="txt-date">
                          {moment(item.time.start).format(
                            'ddd, MMMM, YYYY',
                          )}
                        </div>
                        <div className="txt-time">
                          {moment(item.time.start).format(
                            'h:mm A',
                          )}
                          {' - '}
                          {moment(item.time.end).format(
                            'h:mm A',
                          )}
                        </div>
                      </div>
                    </div>
                  ) : null}

                  <div className="icon-info"></div>
                  <div
                    data-idx={idx}
                    className="card-img"
                    style={{
                      backgroundImage: `url(${item.image})`,
                      filter:
                        (item?.dummy || item.isShowInfo) &&
                        `blur(10px)`,
                    }}
                  ></div>
                  <div className="box-card-text">
                    <h3 className="title">
                      {!item.isShowInfo ? item.title : ''}
                    </h3>
                    <h4 className="sub-title">
                      {!item.isShowInfo
                        ? item.location
                        : ''}
                    </h4>
                  </div>
                  <div
                    className="op-black"
                    style={
                      item?.dummy
                        ? {
                            background: `url(${LockedGridImage})`,
                            backgroundPosition: '50% 50%',
                            backgroundSize: 'cover',
                            opacity: 'unset',
                          }
                        : cardStyles[idx].opBlack
                    }
                  ></div>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  addressEventList: selectAddressEventList,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(({ addressEventList, envRoute }) => {
  useEffect(() => {
    fetchEventFromAddress();
  });
  if (addressEventList.length) {
    return (
      <ListCard
        addressEventList={addressEventList}
        envRoute={envRoute}
      />
    );
  }
  return null;
});
