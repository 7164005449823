import { createSelector } from 'reselect';

const selectUser = (state) => state.user;

export const selectCurrentUser = createSelector(
  [selectUser],
  (user) => user.currentUser,
);

export const selectUserError = createSelector(
  [selectUser],
  (user) => user.error,
);

export const selectIsLogin = createSelector(
  [selectUser],
  (user) => user.isLogin,
);

export const selectLoadingCheckin = createSelector(
  [selectUser],
  (user) => user.isLoading,
);

export const selectDataCheckin = createSelector(
  [selectUser],
  (user) => user.dataCheckIn,
);

export const selectErrorCheckin = createSelector(
  [selectUser],
  (user) => user.errorCheckIn,
);

export const selectAddressWallet = createSelector(
  [selectUser],
  (user) => user.addressWallet,
);
