import React, { useState, useEffect, useMemo } from 'react';
import {
  InfoCircleOutlined,
  CloseCircleOutlined,
  LeftOutlined,
  RightOutlined,
} from '@ant-design/icons';
import './card-detail-page.styles.scss';
import { CSSTransition } from 'react-transition-group';
import Header from '../../components/header/header.component';
import { getEventInfo } from 'src/api/event-api';
import { useParams } from 'react-router';
import { selectAddressWallet } from '../../redux/user/user.selectors';
import { selectAddressEventList } from '../../redux/event/event.selector';
import { fetchEventFromAddress } from '../../redux/event/event.action';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import IconBack from 'src/assets/img/icons/arrow-left-solid.svg';
function toLocal(date) {
  var local = new Date(date);
  local.setMinutes(
    date.getMinutes() - date.getTimezoneOffset(),
  );
  return local.toJSON();
}
const iconStyles = {
  color: '#fff',
  background: '#5b5b5b',
  fontSize: 24,
  borderRadius: '50%',
  border: 'none',
};

const CardDetail = ({
  envRoute,
  fetchEventFromAddress,
  addressEventList,
  ...props
}) => {
  const [isOpenInfo, setIsOpenInfo] = useState(false);
  const [queueImages, setQueueImages] = useState([]);
  const [dataInfo, setDataInfo] = useState(null);
  const [currentImageIdx, setCurrentImageIdx] = useState(
    null,
  );
  const [isMouted, setIsMouted] = useState(false);

  const params = useParams();
  useEffect(() => {
    setIsMouted(true);

    Array.from({ length: 10 }).map((_, idx) => {
      setQueueImages((prev) =>
        prev.concat(
          `/images/poster${idx % 2 === 0 ? '-2' : ''}.png`,
        ),
      );
    });
    setCurrentImageIdx(0);
    if (params.address) {
      fetchEventFromAddress({
        address: params.address,
        history: props.history,
        env: envRoute,
      });
    }
  }, []);

  const toggleInfo = () => setIsOpenInfo((prev) => !prev);

  const prevImage = () => {
    let newIdx = currentImageIdx - 1;
    newIdx = newIdx >= 0 ? newIdx : queueImages.length - 1;
    setCurrentImageIdx(newIdx);
    setDataInfo(queueImages[newIdx]);
  };

  const nextImage = () => {
    let newIdx = currentImageIdx + 1;
    newIdx = newIdx < queueImages.length - 1 ? newIdx : 0;
    setCurrentImageIdx(newIdx);
    setDataInfo(queueImages[newIdx]);
  };

  useEffect(() => {
    if (addressEventList.length) {
      const item = addressEventList.find(
        (item) => item.event.uid === params.eventId,
      );
      setDataInfo(item);
      setQueueImages(addressEventList);
    }
  }, [addressEventList]);
  if (!dataInfo) {
    return null;
  }

  return (
    <div className="card-detail-page">
      <Header />

      <CSSTransition
        in={isMouted}
        timeout={2000}
        classNames="fade"
        unmountOnExit
      >
        <div className="card-detail-container">
          <div className="arrow-back">
            <img
              className="icon-back"
              src={IconBack}
              onClick={() => props.history.goBack()}
            />
          </div>
          <div
            className="card-indicator left"
            onClick={prevImage}
          >
            <div className="indicator-icon">
              <LeftOutlined style={{ fontSize: 24 }} />
            </div>
          </div>

          <div className="card-detail">
            <div className="card-detail--image">
              <div className="card-detail--image-item">
                {(currentImageIdx !== null && (
                  <img
                    src={
                      queueImages[currentImageIdx].event
                        .image.path
                    }
                  />
                )) ||
                  null}
              </div>
            </div>
          </div>

          <div className="card-detail--info">
            <div
              className="card-detail--info-icon"
              onClick={toggleInfo}
            >
              {isOpenInfo ? (
                <CloseCircleOutlined style={iconStyles} />
              ) : (
                <InfoCircleOutlined style={iconStyles} />
              )}
            </div>
            <div
              className={`card-detail--info-content ${
                isOpenInfo ? 'open' : 'close'
              }`}
            >
              <div className="content--header">
                <div>
                  <div>
                    {dataInfo.event
                      ? dataInfo.event.title
                      : null}
                  </div>
                  <div>
                    {new Date(
                      dataInfo.event.created,
                    ).toLocaleDateString()}
                  </div>
                </div>
              </div>
              <div className="content--body">
                <div>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: dataInfo.event.notes,
                    }}
                  />
                </div>
              </div>
              <div className="content--footer">
                <div>{dataInfo.event.location}</div>
              </div>
            </div>
          </div>

          <div
            className="card-indicator right"
            onClick={nextImage}
          >
            <div className="indicator-icon">
              <RightOutlined style={{ fontSize: 24 }} />
            </div>
          </div>
        </div>
      </CSSTransition>
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  addressWallet: selectAddressWallet,
  addressEventList: selectAddressEventList,
});

const mapDispatchToProps = (dispatch) => ({
  fetchEventFromAddress: (data) =>
    dispatch(fetchEventFromAddress(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CardDetail);
