import moment from 'moment-timezone';

export function formatDate(time) {
  let timeZone = time?.startTimeZone || 'GMT';
  let startEvent = moment.tz(time?.start, timeZone);
  let endEvent = moment.tz(time?.end, timeZone);
  let result;
  if (startEvent.date() != endEvent.date()) {
    result = `${startEvent.format(
      'MMM DD',
    )} | ${startEvent.format(
      'hh:mm A',
    )} - ${endEvent.format('MMM DD')} | ${endEvent.format(
      'hh:mm A',
    )}`;
  } else {
    result = `${startEvent.format(
      'MMM DD',
    )} | ${startEvent.format(
      'hh:mm A',
    )} - ${endEvent.format('hh:mm A')}`;
  }
  let timeZoneAbbr = startEvent.format('z');
  if (
    timeZoneAbbr.startsWith('+') ||
    timeZoneAbbr.startsWith('-')
  ) {
    timeZoneAbbr = 'GMT' + timeZoneAbbr;
  } else if (timeZoneAbbr === 'WEST') {
    timeZoneAbbr = 'Lisboa';
  }
  return `${result} (${timeZoneAbbr})`;
}

export function formatDateGallery() {
  const time = moment.tz();
  return time.format('ddd, MMM DD');
}
