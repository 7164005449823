import UserActionTypes from './user.types';

export const googleSignInStart = () => ({
  type: UserActionTypes.GOOGLE_SIGN_IN_START,
});

export const signInSuccess = (user) => ({
  type: UserActionTypes.SIGN_IN_SUCCESS,
  payload: user,
});

export const signInFailure = (error) => ({
  type: UserActionTypes.SIGN_IN_FAILURE,
  payload: error,
});

export const emailSignInStart = (emailAndPassword) => ({
  type: UserActionTypes.EMAIL_SIGN_IN_START,
  payload: emailAndPassword,
});

export const checkUserSession = () => ({
  type: UserActionTypes.CHECK_USER_SESSION,
});

export const signOutStart = () => ({
  type: UserActionTypes.SIGN_OUT_START,
});

export const signOutSuccess = () => ({
  type: UserActionTypes.SIGN_OUT_SUCCESS,
});

export const signOutFailure = (error) => ({
  type: UserActionTypes.SIGN_OUT_FAILURE,
  payload: error,
});

export const signUpStart = (user) => ({
  type: UserActionTypes.SIGN_UP_START,
  payload: user,
});

export const signInStart = (user) => ({
  type: UserActionTypes.SIGN_IN_START,
  payload: user,
});

export const signUpSuccess = ({
  user,
  additionalData,
}) => ({
  type: UserActionTypes.SIGN_IN_SUCCESS,
  payload: { user, additionalData },
});

export const signUpFailure = (error) => ({
  type: UserActionTypes.SIGN_UP_FAILURE,
  payload: error,
});

export const checkInStart = (data) => ({
  type: UserActionTypes.CHECK_IN_START,
  payload: data.address,
  history: data.history,
  idEvent: data.idEvent,
  env: data.env,
});

export const checkInSuccess = (data) => ({
  type: UserActionTypes.CHECK_IN_SUCCESS,
  payload: data,
});

export const checkInFailure = (error) => ({
  type: UserActionTypes.CHECK_IN_FAILURE,
  payload: error,
});

export const setAddress = (address) => ({
  type: UserActionTypes.SET_ADDRESS_WALLET,
  payload: address,
});
