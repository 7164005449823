const EventActionTypes = {
  FETCH_EVENT_INFO: 'FETCH_EVENT_INFO',
  FETCH_EVENT_INFO_SUCCESS: 'FETCH_EVENT_INFO_SUCCESS',
  FETCH_EVENT_INFO_FAILURE: 'FETCH_EVENT_INFO_FAILURE',
  FETCH_EVENT_FROM_ADDRESS: 'FETCH_EVENT_FROM_ADDRESS',
  FETCH_EVENT_FROM_ADDRESS_SUCCESS:
    'FETCH_EVENT_FROM_ADDRESS_SUCCESS',
  FETCH_EVENT_FROM_ADDRESS_FAILURE:
    'FETCH_EVENT_FROM_ADDRESS_FAILURE',
  CHECK_IS_EXIST_ADDRESS: 'CHECK_IS_EXIST_ADDRESS',
};

export default EventActionTypes;
