import { createSelector } from 'reselect';

const selectExample = (state) => state.example;

export const selectIsLoadingList = createSelector(
  [selectExample],
  (example) => example.isLoadingList,
);

export const selectListExample = createSelector(
  [selectExample],
  (example) => example.list,
);

export const selectIsLoadingAdd = createSelector(
  [selectExample],
  (example) => example.isLoadingAdd,
);

export const selectIsOpenAdd = createSelector(
  [selectExample],
  (example) => example.isOpenAdd,
);

export const selectParamsFetch = createSelector(
  [selectExample],
  (example) => example.paramsFetchList,
);

export const selectItemUpdate = createSelector(
  [selectExample],
  (example) => example.itemUpdate,
);

export const selectIsLoadingEdit = createSelector(
  [selectExample],
  (example) => example.isLoadingEdit,
);

export const selectIsOpenEdit = createSelector(
  [selectExample],
  (example) => example.isOpenEdit,
);

export const selectItemEdit = createSelector(
  [selectExample],
  (example) => example.itemEdit,
);

export const selectIdDelete = createSelector(
  [selectExample],
  (example) => example.idDelete,
);

export const selectIsOpenDelete = createSelector(
  [selectExample],
  (example) => example.isOpenDelete,
);

export const selectIsLoadingDelete = createSelector(
  [selectExample],
  (example) => example.isLoadingDelete,
);
