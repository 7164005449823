import React, { useEffect, lazy, Suspense } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { selectIsLogin } from 'src/redux/user/user.selectors';
import { checkUserSession } from '../redux/user/user.actions';
import Spinner from 'src/components/spinner/spinner.component';
import ErrorBoundary from 'src/components/error-boundary/error-boundary.component';
import Admin from './Pages';

const LoginPage = lazy(() =>
  import('../views/sign-in/sign-in.component'),
);

const App = ({ checkUserSession, isLogin }) => {
  useEffect(() => {
    checkUserSession();
  }, [checkUserSession]);
  return (
    <div>
      <ErrorBoundary>
        <Suspense fallback={<Spinner />}>
          <Switch>
            <Route
              path="/"
              render={() =>
                isLogin ? (
                  <Admin />
                ) : (
                  <Redirect to="/login" />
                )
              }
            />
            <Route
              path="/login"
              render={() =>
                isLogin ? (
                  <Redirect to="/" />
                ) : (
                  <LoginPage />
                )
              }
            />
            <Redirect from="/" to="/home" />
          </Switch>
        </Suspense>
      </ErrorBoundary>
    </div>
  );
};

const AppWithoutAuthen = ({
  checkUserSession,
  isLogin,
}) => {
  useEffect(() => {
    // checkUserSession();
  }, [checkUserSession]);
  return (
    <div>
      <ErrorBoundary>
        <Suspense fallback={<Spinner />}>
          <Switch>
            <Route path="/" render={() => <Admin />} />
            <Redirect from="/" to="/home" />
          </Switch>
        </Suspense>
      </ErrorBoundary>
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  isLogin: selectIsLogin,
});

const mapDispatchToProps = (dispatch) => ({
  checkUserSession: () => dispatch(checkUserSession()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AppWithoutAuthen);
