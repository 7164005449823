import { createSelector } from 'reselect';

const selectEvent = (state) => state.event;

export const selectIsLoading = createSelector(
  [selectEvent],
  (event) => event.isLoading,
);

export const selectEventInfo = createSelector(
  [selectEvent],
  (event) => event.event,
);

export const selectEventError = createSelector(
  [selectEvent],
  (event) => event.error,
);

export const selectIsLoadingAddressEvent = createSelector(
  [selectEvent],
  (event) => event.isLoadingEventAdrress,
);

export const selectAddressEventList = createSelector(
  [selectEvent],
  (event) => event.listEventToken,
);

export const selectEventListError = createSelector(
  [selectEvent],
  (event) => event.error,
);
