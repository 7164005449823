import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import '../home-page/home-page.styles.scss';
import { createStructuredSelector } from 'reselect';
import { CSSTransition } from 'react-transition-group';

import Header from '../../components/header/header.component';
import HarmonyLogo from 'src/assets/img/HarmonyLogo.svg';
import Close from 'src/assets/img/CloseIcon.svg';
import LogoWithBG from 'src/assets/img/HarmonyLogoWithBg.svg';
import Loading from 'src/assets/img/icons/loading.gif';
import { setAddress } from '../../redux/user/user.actions';
import {
  fetchEventFromAddress,
  checkIsExistAddress,
} from '../../redux/event/event.action';
import { checkAddressValidation } from '../../utils/checkValidation';

const Welcome = (props) => {
  const {
    envRoute,
    isLoadingCheckin,
    setAddressWallet,
    fetchEventsAddress,
    checkIsExistAddress,
  } = props;
  const [address, setAddress] = useState('');
  const [
    showUnValidAddress,
    setshowUnValidAddress,
  ] = useState(false);

  const handleSubmitAddress = () => {
    if (!showUnValidAddress) {
      checkIsExistAddress({
        address: address,
        history: props.history,
        env: envRoute,
      });
      setAddressWallet(address);
    }
  };

  const [isMouted, setIsMouted] = useState(false);
  const [addressHolder, setAddressHolder] = useState(null);

  useEffect(() => {
    setIsMouted(true);
  }, []);

  function formatHideAddress(address, num = 6) {
    return `${address.substr(0, num)}...${address.substr(
      address.length - num,
      address.length,
    )}`;
  }

  function handleAddressHolder(event) {
    event.preventDefault();

    const type = event.type;
    const isBlur = type === 'blur';
    const isFocus = type === 'focus';
    const isClick = type === 'click';
    const value = address;
    const length = value.length;

    if (!type && !isFocus && !isBlur) return;

    if (isBlur && address && address.length >= 42) {
      setAddressHolder(formatHideAddress(address, 14));
      event.target.isScrollToEnd = false;
      return;
    }

    if (isClick && !event.target.isScrollToEnd) {
      event.target.setSelectionRange(length, length);
      event.target.scrollLeft = event.target.scrollWidth;
      event.target.isScrollToEnd = true;
    }

    setAddressHolder(null);
  }

  return (
    <div className="home-page">
      <Header />
      <CSSTransition
        in={isMouted}
        timeout={2000}
        classNames="fade"
        unmountOnExit
      >
        <div className="main-content">
          <div className="infor-card">
            <div className="title-wallet-popup">
              <img src={HarmonyLogo} />
              &nbsp;Harmony
            </div>
            <div className="main-wallet-popup">
              <div className="title">
                Welcome Harmonauts
              </div>
              <div className="brief">
                Capture the moment - browse, sell, gift, and
                print NFT into IRL collectibles, all with
                Harmony $ONE.
              </div>
              <div className="img-container">
                <img src={LogoWithBG} />
              </div>
              <div
                style={{
                  visibility: !showUnValidAddress
                    ? 'hidden'
                    : 'visible',
                }}
                className="error-message"
              >
                Invalid wallet address
              </div>
              <input
                className="input-container"
                placeholder="One wallet address"
                value={addressHolder || address}
                onChange={(e) => {
                  setAddress(e.target.value);
                  if (
                    checkAddressValidation(e.target.value)
                  ) {
                    setshowUnValidAddress(false);
                  } else {
                    setshowUnValidAddress(true);
                  }
                }}
                onClick={handleAddressHolder}
                onFocus={handleAddressHolder}
                onBlur={handleAddressHolder}
              />
              <div className="other-options">
                <span>No wallet?&nbsp;</span>
                <a
                  href="https://1wallet.crazy.one/create"
                  target="blank"
                >
                  Create
                </a>
                <span>&nbsp;$one!</span>
              </div>
              <div
                className="browse-button"
                style={{
                  background:
                    !showUnValidAddress &&
                    address != '' &&
                    '#69FABD',
                  opacity:
                    !showUnValidAddress &&
                    address != '' &&
                    1,
                }}
                onClick={handleSubmitAddress}
              >
                <img
                  src={Loading}
                  width="17px"
                  style={{
                    display: !isLoadingCheckin && 'none',
                  }}
                />
                &nbsp;&nbsp; Check In and Browse
              </div>
            </div>
            <div className="extra-text popup">
              We do not own your private keys and cannot
              access your funds without your confirmation.
            </div>
          </div>
        </div>
      </CSSTransition>
      <div className="footer"></div>
    </div>
  );
};

const mapStateToProps = createStructuredSelector({});

const mapDispatchToProps = (dispatch) => ({
  setAddressWallet: (address) =>
    dispatch(setAddress(address)),
  fetchEventsAddress: (address) => {
    dispatch(fetchEventFromAddress(address));
  },
  checkIsExistAddress: (data) => {
    dispatch(checkIsExistAddress(data));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Welcome);
