import Axios from './custom-axios';
import Cookies from '../utils/cookies';
export function authLogin(user) {
  return Axios({
    method: 'POST',
    url: '/auth/login',
    data: user,
  }).then((res) => {
    if (res.data.token) {
      Cookies.set('token', `Bearer ${res.data.token}`, {
        path: '/',
      });
    }
    return res.data;
  });
}
export function authSession() {
  return Axios({
    method: 'GET',
    url: '/auth/auth-token',
  }).then((res) => res.data);
}

export function checkInAPI(data, env) {
  const body = {
    wallet_address: data?.payload,
  };
  return Axios({
    method: 'POST',
    url: `https://api.timeless.space/${env}/moments/${data.idEvent}`,
    data: body,
  }).then((res) => res);
}
