import HomePage from 'src/views/home-page/home-page.component';
import ListCardPage from './views/list-card-page/list-card-page.component';
import Test from 'src/views/test/test-page.component';
import Welcome from 'src/views/welcome/welcome.component';
import CardDetail from 'src/views/card-detail/card-detail-page.component';

const homeRoutes = [
  {
    path: '/',
    name: 'Home',
    component: Welcome,
    layout: '/',
  },
  {
    path: '/gallery/:address',
    name: 'list-card',
    component: ListCardPage,
    layout: '/',
  },
  // {
  //   path: '/test',
  //   name: 'Test',
  //   component: Test,
  //   layout: '/',
  // },
  {
    path: '/gallery-detail/:address/:eventId',
    name: 'CardDetail',
    component: CardDetail,
    layout: '/',
  },
  {
    path: '/:id',
    name: 'Home',
    component: HomePage,
    layout: '/',
  },
];

export default homeRoutes;
